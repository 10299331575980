<template>
    <section class="tw-content col-12">
        <div class="bg-white rounded">
            <div class="row p-4">
                <div class="col-auto text-end me-0 ms-auto d-flex p-0">
                    <router-link to="/events" class="btn tw-default-bg-color text-white me-2 btn-event-categories" title="Back to Events">
                        <span class="rounded rounded-pill fs-4"><i class="fas fa-arrow-circle-left"></i></span>
                    </router-link>
                    <button @click="openCategoryModal" data-bs-toggle="modal" data-bs-target="#category-add" class="tw-red-bg-color text-white d-flex align-items-center rounded-3 border-0 btn-event-categories text-center">
                        <span class="d-inline-flex p-1 rounded rounded-pill" title="Add Category">
                            <i class="fas fa-plus fa-2x"></i>
                        </span>
                    </button>
                </div>
            </div>
            <div class="table-responsive p-4">
                <table class="tw-table table text-nowrap">
                    <thead>
                        <tr class="p-1">
                            <th>Name</th>
                            <th>Slug</th>
                            <th>Background color</th>
                            <th>Text color</th>
                            <th>Font size</th>
                            <th>Font style</th>
                            <th>Font weight</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="category in categories" :key="`category-${category.id}`" class="p-1">
                            <td>
                                {{ category.name }}
                                <span v-if="category.count" class="badge bg-light text-dark">{{ category.count }} </span>
                            </td>
                            <td>{{ category.slug }}</td>
                            <td class="px-2">
                                <span
                                    v-if="category.style"
                                    :style="{
                                        backgroundColor: category.style.backgroundColor ? category.style.backgroundColor : '#e3c3c1',
                                        color: category.style.color ? category.style.color : '#ffffff',
                                        fontSize: category.style.fontSize ? category.style.fontSize : '12px',
                                        fontStyle: category.style.fontStyle ? category.style.fontStyle : 'normal',
                                        fontWeight: category.style.fontWeight ? category.style.fontWeight : 'normal',
                                    }"
                                    class="p-2 rounded"
                                    >{{ category.name }}
                                </span>
                                <span v-else>{{ category.name }}</span>
                            </td>
                            <td>
                                <span v-if="category.style">{{ category.style.color }}</span
                                ><span v-else></span>
                            </td>
                            <td>
                                <span v-if="category.style">{{ category.style.fontSize }}</span
                                ><span v-else></span>
                            </td>

                            <td>
                                <span v-if="category.style">{{ category.style.fontStyle }}</span
                                ><span v-else></span>
                            </td>
                            <td>
                                <span v-if="category.style">{{ category.style.fontWeight }}</span
                                ><span v-else></span>
                            </td>
                            <td class="text-end column-actions">
                                <div class="d-flex justify-content-end">
                                    <a @click.prevent="removeCategory(category.id)" class="d-flex align-items-center cursor-pointer"
                                        ><span class="fa-stack"><i class="tw-light-red-color fa fa-circle fa-stack-2x"></i><i class="fa fa-trash fa-stack-1x fa-inverse"></i></span
                                    ></a>
                                    <a @click="getEventSingleCategory(category.id)" data-bs-toggle="modal" data-bs-target="#category-add" class="d-flex align-items-center cursor-pointer"
                                        ><span class="fa-stack"><i class="tw-light-red-color fa fa-circle fa-stack-2x"></i><i title="Update single event" class="fas fa-pencil-alt fa-stack-1x fa-inverse"></i></span
                                    ></a>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr class="text-center">
                            <td v-if="!categories.length" colspan="7">No categories found, please add category</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>

        <!-- Add/Edit category modal -->
        <div class="modal fade" id="category-add" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header tw-red-bg-color text-white">
                        <div class="d-flex">
                            <span class="tw-create-modal-icon d-inline-flex justify-content-center align-items-center tw-light-red-bg-color me-3">
                                <i class="fas fa-plus"></i>
                            </span>
                            <span class="d-inline-flex">
                                <div v-if="!editing" class="row">
                                    <h5 class="modal-title col-12" id="modal-title">Add category</h5>
                                    <p class="col-12"></p>
                                </div>
                                <div v-else class="row">
                                    <h5 class="modal-title col-12" id="modal-title">Editing {{ newCategory.name }}</h5>
                                </div>
                            </span>
                        </div>
                    </div>
                    <form @submit.prevent.stop="storeEventCategory">
                        <div class="row p-3">
                            <div class="col-12 mb-3">
                                <label for="category-name" class="form-label">Category name </label>
                                <input id="category-name" type="text" class="form-control rounded-pill p-2" v-model="newCategory.name" placeholder="Enter category name" required />
                            </div>
                            <div class="event-category-style">
                                <span>
                                    <a type="button" @click="toggleCategoryStyle" class="btn btn-light btn-sm rounded rounded-pill cursor-pointer">
                                        {{ categoryStyle ? "Hide style" : "Set style" }}
                                    </a>
                                </span>

                                <div v-if="categoryStyle">
                                    <div class="col-12 my-3">
                                        <label for="background-color" class="form-label">Background color </label>
                                        <input id="background-color" type="color" class="form-control form-control-lg rounded-pill" v-model="newCategory.style.backgroundColor" />
                                    </div>
                                    <div class="col-12 mb-3">
                                        <label for="text-color" class="form-label">Text color </label>
                                        <input id="text-color" type="color" class="form-control form-control-lg rounded-pill" v-model="newCategory.style.color" />
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-11">
                                            <label for="font-size" class="form-label">Text font size </label>
                                            <input id="font-size" type="number" min="0" class="form-control rounded-pill p-2" v-model="newCategory.style.fontSize" />
                                        </div>
                                        <div class="col-1 pt-5">
                                            <span>px</span>
                                        </div>
                                    </div>
                                    <div class="col-12 mb-3">
                                        <label for="font-style" class="form-label">Text font style</label>
                                        <select id="font-style" class="form-select" v-model="newCategory.style.fontStyle">
                                            <option value="normal">Normal</option>
                                            <option value="italic">Italic</option>
                                            <option value="oblique">Oblique</option>
                                        </select>
                                    </div>
                                    <div class="col-12 mb-3">
                                        <label for="font-weight" class="form-label">Text font weight</label>
                                        <select id="font-weight" class="form-select" v-model="newCategory.style.fontWeight">
                                            <option value="lighter">Lighter</option>
                                            <option value="normal">Normal</option>
                                            <option value="medium">Medium</option>
                                            <option value="bold">Bold</option>
                                            <option value="bolder">Bolder</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 my-3">
                                <button class="tw-red-bg-color text-white py-2 px-3 rounded-pill border-0">
                                    <i class="fas fa-plus"></i>
                                    <span class="fs-5 ps-2">{{ editing ? "Update" : "Create" }} category</span>
                                </button>
                                <button class="btn tw-dark-grey-bg-color text-white rounded-pill float-end fs-5 cursor-pointer" type="button" @click="closeCategoryModal">Cancel</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import heyGovMixin from "@/mixins/heyGovMixin";
import { Modal } from "bootstrap";

export default {
    name: "EventCategories",
    data: () => ({
        categories: [],
        category: null,
        newCategory: {
            name: "",
            style: {
                backgroundColor: "",
                color: "",
                fontSize: "",
                fontWeight: "",
                fontStyle: "",
            },
        },
        categoryStyle: false,
        editing: false,
    }),
    mounted() {
        this.getEventCategories();
        this.$modalCategory = new Modal(document.getElementById("category-add"));
    },
    methods: {
        async addNewCategory() {
            if (this.newCategory.style.fontSize) {
                this.newCategory.style.fontSize = `${this.newCategory.style.fontSize}px`;
            }

            let res = await this.axios.post(`${this.apiRoute}/${this.domain}/events/categories?apiKey=${this.apiKey}`, this.newCategory);
            if (res) {
                if (res.data.style) {
                    res.data.style = JSON.parse(res.data.style);
                }
                this.closeCategoryModal();
                this.getEventCategories();
            }
        },
        async updateCategory() {
            if (this.editing) {
                if (this.newCategory.style.fontSize) {
                    this.newCategory.style.fontSize = parseInt(this.newCategory.style.fontSize);
                    this.newCategory.style.fontSize = `${this.newCategory.style.fontSize}px`;
                }

                const res = await this.axios.put(`${this.apiRoute}/${this.domain}/events/categories/${this.newCategory.id}?apiKey=${this.apiKey}`, {
                    name: this.newCategory.name,
                    style: this.newCategory.style,
                });
                if (res) {
                    this.closeCategoryModal();
                    this.categories = [];
                    this.getEventCategories();
                }
            }
        },
        async removeCategory(id) {
            if (confirm("Deleting category will also delete events within this category 🤷‍♀️. Want to proceed?")) {
                let res = await this.axios.delete(`${this.apiRoute}/${this.domain}/events/categories/${id}?apiKey=${this.apiKey}`);
                if (res && res.status === 202) {
                    this.categoris = [];
                    this.getEventCategories();
                }
            }
        },
        async getEventCategories() {
            let res = await this.axios.get(`${this.apiRoute}/${this.domain}/events/categories`);
            if (res && res.status === 200) {
                this.categories = res.data;
            }
        },
        async getEventSingleCategory(id) {
            let res = await this.axios.get(`${this.apiRoute}/${this.domain}/events/categories/${id}`);
            if (res && res.status === 200) {
                this.newCategory = res.data;
                if (this.newCategory.style.fontSize) {
                    this.newCategory.style.fontSize = parseInt(this.newCategory.style.fontSize);
                }
                this.editing = true;
                this.openCategoryModal();
            }
        },
        openCategoryModal() {
            this.$modalCategory.show();
        },
        closeCategoryModal() {
            this.editing = false;
            this.newCategory = {
                name: "",
                style: {
                    backgroundColor: "",
                    color: "",
                    fontSize: "",
                    fontWeight: "",
                    fontStyle: "",
                },
            };
            this.$modalCategory.hide();
        },
        storeEventCategory() {
            if (this.editing) {
                this.updateCategory();
            } else {
                this.addNewCategory();
            }
        },
        toggleCategoryStyle() {
            if (this.categoryStyle === true) {
                this.categoryStyle = false;
            } else {
                this.categoryStyle = true;
            }
        },
    },
    mixins: [heyGovMixin],
};
</script>
<style scoped>
.btn-event-categories {
    border-radius: 10px !important;
}
</style>
