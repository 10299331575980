<template>
    <ul id="tw-events-style" class="nav nav-tabs nav-fill border-0">
        <li class="nav-item cursor-pointer">
            <a class="nav-link tw-light-red-color text-uppercase" :class="{ active: tab === 'general' }" @click="setTab('general')">General style</a>
        </li>
        <li class="nav-item cursor-pointer">
            <a class="nav-link tw-light-red-color text-uppercase" :class="{ active: tab === 'categories' }" @click="setTab('categories')"><span>Categories</span></a>
        </li>
    </ul>
    <div v-if="tab === 'general'">
        <EventsGeneral />
    </div>
    <div v-if="tab === 'categories'">
        <EventCategories />
    </div>
</template>

<script>
import EventsGeneral from "./EventsGeneral";
import EventCategories from "./EventCategories";
export default {
    name: "EventsStyle",
    data() {
        return {
            tab: "general",
        };
    },
    components: { EventsGeneral, EventCategories },
    methods: {
        setTab(tabName) {
            if (tabName === "general") {
                this.tab = "general";
            } else {
                this.tab = "categories";
            }
        },
    },
};
</script>
