<template>
    <section class="tw-content">
        <div class="row bg-white">
            <div class="col-md-4 col-sm-12 mt-5 ps-3">
                <div class="card">
                    <div class="card-body">
                        <div class="mb-3">
                            <label for="font-family" class="form-label">Font family</label>
                            <select id="font-family" class="form-select form-select-sm" v-model="fontFamily">
                                <option value="Agrandir">Agrandir</option>
                                <option value="Roboto">Roboto</option>
                                <option value="Inter">Inter</option>
                                <option value="Lora">Lora</option>
                                <option value="Oxygen">Oxygen</option>
                                <option value="Spartan">Spartan</option>
                                <option value="OpenSans">Open Sans</option>
                                <option value="Trirong">Trirong</option>
                                <option value="MartelSans">Martel Sans</option>
                                <option value="LibreFranklin">Libre Franklin</option>
                                <option value="Overpass">Overpass</option>
                                <option value="Nunito">Nunito</option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="text-color" class="form-label"
                                >Primary text color <small><i title="Calendar primary color" class="fas fa-question-circle"></i></small>
                            </label>
                            <div class="row">
                                <div class="col-6">
                                    <input type="text" class="form-control form-control-sm rounded-pill" placeholder="Type or paste color hex code" v-model="color" />
                                </div>
                                <div class="col-6">
                                    <input id="text-color" type="color" class="form-control form-control-sm rounded rounded-pill" v-model="color" />
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="secondary-text-color" class="form-label"
                                >Secondary text color <small><i title="Calendar week days color" class="fas fa-question-circle"></i></small>
                            </label>
                            <div class="row">
                                <div class="col-6">
                                    <input type="text" class="form-control form-control-sm rounded-pill" placeholder="Type or paste color hex code" v-model="secondaryColor" />
                                </div>
                                <div class="col-6">
                                    <input id="secondary-text-color" type="color" class="form-control form-control-sm rounded rounded-pill" v-model="secondaryColor" />
                                </div>
                            </div>
                        </div>

                        <div class="row mb-3">
                            <label for="font-size">Text font size</label>
                            <div class="input-group input-group-sm">
                                <input id="font-size" type="number" class="form-control rounded-end rounded-pill" min="0" v-model="fontSize" />
                                <span class="input-group-text rounded-start rounded-pill">px</span>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="font-style" class="form-label">Text font style</label>
                            <select id="font-style" class="form-select form-select-sm" v-model="fontStyle">
                                <option value="normal">Normal</option>
                                <option value="italic">Italic</option>
                                <option value="oblique">Oblique</option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="font-weight" class="form-label">Text font weight</label>
                            <select id="font-weight" class="form-select form-select-sm" v-model="fontWeight">
                                <option value="lighter">Lighter</option>
                                <option value="normal">Normal</option>
                                <option value="bold">Bold</option>
                                <option value="bolder">Bolder</option>
                            </select>
                        </div>

                        <div class="row mb-3">
                            <label for="filter-border-radius" class="form-label"
                                >Border Radius
                                <small><i title="Calendar filter border radius" class="fas fa-question-circle"></i></small>
                            </label>
                            <div class="input-group input-group-sm">
                                <input id="filter-border-radius" type="number" class="form-control rounded-end rounded-pill" min="0" v-model="borderRadius" />
                                <span class="input-group-text rounded-start rounded-pill">px</span>
                            </div>
                        </div>
                        <div class="my-3">
                            <a type="button" @click="updateEventsStyle" class="btn tw-default-bg-color text-white me-2 btn-event-categories float-end">Save style </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-8 col-sm-12 events-iframe">
                <div class="mt-2">
                    <router-link to="/events" class="btn tw-default-bg-color text-white me-2 btn-event-categories float-end" title="Back to Events">
                        <span class="rounded rounded-pill fs-5"><i class="fas fa-arrow-circle-left"></i></span>
                    </router-link>
                </div>
                <div class="row">
                    <div v-if="!iframeLoaded" class="col-12 text-center mt-5">
                        <span><i class="fas fa-spinner fa-spin fa-3x"></i></span>
                    </div>
                    <iframe class="heygov-events" :src="`${appRoute}/${domain}/events-embed`" frameborder="0" style="min-width: 100%; height: 1000px" scrolling="no" height="100%" width="100%" @load="iframeLoad"></iframe>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import heyGovMixin from "@/mixins/heyGovMixin";
export default {
    name: "EventsGeneral",
    data() {
        return {
            fontFamily: "Agrandir",
            color: "#14142b",
            secondaryColor: "#FCAB1C",
            backgroundColor: "#FFFFFF",
            fontWeight: "normal",
            fontStyle: "normal",
            fontSize: "16",
            borderRadius: "15",
            jurisdiction: null,
            eventStyles: null,
            validColor: true,
            validSecondaryColor: true,
            iframeLoaded: false,
        };
    },
    created() {
        this.loadEventStyles();
    },
    mounted() {},
    methods: {
        async updateEventsStyle() {
            let styleObject;
            styleObject = {
                borderRadius: `${this.borderRadius}px`,
                fontSize: `${this.fontSize}px`,
                fontFamily: this.fontFamily,
                fontWeight: this.fontWeight,
                fontStyle: this.fontStyle,
                color: this.color,
                secondaryColor: this.secondaryColor,
                backgroundColor: this.backgroundColor,
            };

            let res = await this.axios.put(`${this.apiRoute}/jurisdictions/${this.jurisdiction.id}?apiKey=${this.apiKey}`, {
                style: styleObject,
            });
            if (res && res.data) {
                this.$router.go(0).tab = "general";
            }
        },
        async loadEventStyles() {
            this.jurisdiction = await this.heyGovJurisdiction();
            let res = await this.axios.get(`${this.apiRoute}/jurisdictions/styles/${this.jurisdiction.id}`);
            this.eventStyles = res.data;
            if (this.eventStyles) {
                this.fontSize = this.eventStyles.fontSize.replace("px", "");
                this.borderRadius = this.eventStyles.borderRadius.replace("px", "");
                this.color = this.eventStyles.color;
                this.secondaryColor = this.eventStyles.secondaryColor;
                this.backgroundColor = this.eventStyles.backgroundColor;
                this.fontWeight = this.eventStyles.fontWeight;
                this.fontStyle = this.eventStyles.fontStyle;
                this.fontFamily = this.eventStyles.fontFamily;
            }
        },
        iframeLoad() {
            return (this.iframeLoaded = true);
        },
    },
    mixins: [heyGovMixin],
};
</script>
<style scoped>
.btn-event-categories {
    border-radius: 10px !important;
}
.accordion-button {
    padding: 0.75rem;
}
</style>
